import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import Image from 'next/legacy/image'
import { FormattedMessage } from 'react-intl'
import useSWR from 'swr'
import useAuth from '@/hooks/auth'
import { AUTH_HOST } from '../../../config'
import Signin from './signin'
import Signup from './signup'
import styles from './auth.module.scss'
import { emailAuth, emailStatus } from '@data/api/auth'

interface Props {
  isSignin: boolean
}

const openWindow = (url: string, name: string) => {
  let top = window.screen.height - 500
  let left = window.screen.width - 500
  top = top > 0 ? top / 2 : 0
  left = left > 0 ? left / 2 : 0

  const authWindow = window.open(
    url,
    name,
    `width=500,height=500,top=${top},left=${left}`,
  )
  authWindow.moveTo(left, top)
  authWindow.focus()
}

const signInWithFacebook = () => {
  openWindow(`${AUTH_HOST}/facebook`, 'facebookauth')
}

const signInWithTwitter = () => {
  openWindow(`${AUTH_HOST}/twitter`, 'twitterauth')
}

/*
const signInWithGoogle = () => {
  openWindow(`${AUTH_HOST}/google`, 'googleauth')
}
*/

const fetcher = async ([ , id ]: [ url: string, id: string]) => {
  const result = await emailStatus(id)
  if (!result.token) {
    return
  }
  return { token: result.token }
}

const Auth = (props: Props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const [ isSignin, setSignin ] = useState(props.isSignin)
  const [ sentEmail, setEmail ] = useState('')
  const [ isSending, setSending ] = useState(false)
  const [ isSend, setSent ] = useState(false)
  const [ authId, setAuthId ] = useState<string>()
  const { data: status } = useSWR(authId ? [ 'api/email/status', authId ] : null, fetcher, {
    revalidateOnFocus: true,
    refreshInterval: 5000,
  })
  const { signin } = useAuth()

  const signInWithEmail = async (email: string) => {
    setSending(true)
    const result = await emailAuth(email)
    setSending(false)

    if (result.error) {
      // show message
      return
    }
    setEmail(email)
    setSent(true)
    setAuthId(result.id)
  }

  useEffect(() => {
    if (status && status.token) {
      signin(status.token)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ status ])

  useEffect(() => {
    const afterAuth = (e: StorageEvent) => {
      if (e.key !== 'token') {
        return
      }
      signin(e.newValue)
      localStorage.removeItem('token')
    }
    window.addEventListener('storage', afterAuth)
    return () => window.removeEventListener('storage', afterAuth)
  })

  return (
    <div className={styles.container}>
      {isSend ? (
        <div className={styles.checkInbox}>
          <h2 className="tx-center tx-oswald tx-w400 mb-5">Check your inbox</h2>
          <p className="lead">
            <FormattedMessage
              id="auth.email.sent"
              // eslint-disable-next-line max-len
              defaultMessage="We just emailed a confirmation link to {email}. Click the link, and you’ll be signed in."
              values={{ email: sentEmail }}
            />
            (
            <button
              type="button"
              className="btn-link"
              onClick={() => setSent(false)}
            >
              undo
            </button>
            )
          </p>
          <p className="tx-14">
            <FormattedMessage
              id="auth.email.note"
              // eslint-disable-next-line max-len
              defaultMessage="Note: If you close this page, you will not be able to complete your sign in."
            />
          </p>
          <div className={styles.inboxImage}>
            <Image
              src="/static/images/check-inbox.svg"
              width={300}
              height={300}
              alt="Check your inbox"
            />
          </div>
        </div>
      ) : (
        <>
          <div
            className={classnames(styles.heroBg, {
              [styles.signup]: !isSignin,
            })}
          />
          {isSignin ? (
            <Signin
              signInWithFacebook={signInWithFacebook}
              signInWithTwitter={signInWithTwitter}
              // signInWithGoogle={signInWithGoogle}
              signInWithEmail={signInWithEmail}
              toggleSignin={() => setSignin(false)}
              isSending={isSending}
            />
          ) : (
            <Signup
              // signInWithFacebook={signInWithFacebook}
              signInWithTwitter={signInWithTwitter}
              // signInWithGoogle={signInWithGoogle}
              signInWithEmail={signInWithEmail}
              toggleSignin={() => setSignin(true)}
              isSending={isSending}
            />
          )}
        </>
      )}
    </div>
  )
}

// Auth.defaultProps = {
// }

export default Auth
